.gp-view {
    background: $white;
    border-radius: 8px;
    box-shadow: 0 10px 40px rgba($black, 0.1);
    display: flex;
    flex-direction: column;
    height: auto;
    overflow-x: scroll;
    position: relative;

    min-height: 100vh;

    @media #{$tablet} {
        overflow-x: auto;
        min-height: auto;
    }

    @media #{$desktop} {
        flex-direction: row;
    }

    &__sidebar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 2rem 1.5rem;

        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

        @media #{$desktop} {
            box-shadow: none;
        }

        @media #{$desktop} {
            min-height: 620px;
            width: 33%;
        }

        h1 {
            font-family: $font-secondary;
            color: $gravity-blue;
            letter-spacing: 0.15rem;
            margin-bottom: .5rem;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 1.3;
            font-size: 1.5rem;
        }

        p {
            font-weight: 400;
        }

        h2 {
            font-family: $font-secondary;
            font-size: 0.875rem;
            font-weight: 700;
            letter-spacing: 0.15rem;
            margin: 0.5rem 0;
            padding-top: 0.75rem;
            text-transform: uppercase;
            position: relative;

            &::before {
                content: url('/img/icon-bolt.svg');
                position: absolute;
                top: -2.75rem;
            }

            &::after {
                background: url('/img/dotted-rule-tile.png') repeat-x;
                background-size: 11px 2px;
                content: '';
                display: block;
                height: 3px;
                position: absolute;
                top: 0;
                width: 100%;
                z-index: 1;
            }
        }

        &__helper-text {
            display: none;

            @media #{$desktop} {
                display: block;
            }
        }


        &__extra {
            display: none;

            h3 {
                font-family: $font-secondary;
                color: $gravity-blue;
                margin-top: 1.4rem;
                text-transform: uppercase;
                font-weight: 900;
                line-height: 1.3;
                font-size: .9rem;
            }



            @media #{$desktop} {
                display: block;
                margin-bottom: auto;
            }
        }
    }
}

.gp-form {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

    padding-top: 2rem;

    @media #{$desktop} {
        padding-top: 0;
    }

    @media #{$tablet} {
        overflow-x: auto;
        flex-direction: row;
        min-height: 620px;
    }

    .gp-form-elements {
        padding: 0 2rem 2rem;
        position: relative;
        width: 100%;

        @media #{$desktop} {
            display: flex;
            flex-direction: column;
            padding: 3rem;
        }

        &::before {
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 2rem;
        }

        @media #{$desktop} {
            &::before {
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0.12+0,0+100 */
                background: -moz-linear-gradient(left, rgba(0,0,0,0.04) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left, rgba(0,0,0,0.04) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right, rgba(0,0,0,0.04) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1f000000', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
            }
        }

        h3 {
            font-family: $font-secondary;
            color: $gravity-green;
            margin-top: .5rem;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 1.3;
            font-size: 1.2rem;
        }

        h4 {
            font-family: $font-secondary;
            color: $gravity-blue;
            margin-top: .5rem;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 1.3;
            font-size: 1.0rem;
        }

        &__page-indicator {
            display: none;

            @media #{$desktop} {
                display: flex;
                justify-content: space-between;
                margin: 0 auto 3rem;
                position: relative;
                width: 88%;
            }

            li {
                align-items: center;
                background: rgba($dark-gray, 0.1);
                border-radius: 40px;
                color: rgba($dark-gray, 0.4);
                display: flex;
                font-weight: 700;
                height: 40px;
                justify-content: center;
                text-align: center;
                width: 40px;
                position: relative;

                &.current {
                    background: $gravity-green;
                    color: $white;
                }

                &.complete {
                    background: $gravity-green;
                    color: $gravity-green;
                }

                &::after {
                    content: url('/img/dotted-divider.svg');
                    width: 100%;
                    height: auto;
                    max-width: 26px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-55%);

                    @media (min-width: 992px) {
                        left: 180%;
                    }
                }

                &:last-child::after {
                    display: none;
                }
            }
        }

        label {
            font-family: $site-font;
            font-size: .875rem;
            font-weight: 600;
        }

        input[type="text"],
        input[type="number"],
        input[type="email"],
        input[type="password"],
        input[type="tel"],
        input[type="url"],

        text-area {
            width: 100%;
            margin-bottom: .5rem;
            padding: .5rem;
            display: block;
            height: 34px;
            background: #f9f9fb;
            border-radius: .25rem;
            border: 1px solid #ddd;
            transition: $transition-fast;

            &:focus {
                border-color: $gravity-green;
                outline: none;
            }
        }

        select {
            width: 100%;
            margin-bottom: .5rem;
            padding-left: 0.5rem;
            vertical-align: middle;
            display: block;
            background: #f9f9fb;
            border-radius: .25rem;
            border: 1px solid #ddd;
            transition: 0.15s;
            height: 34px;
        }


        .form-group {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 1rem;

            .input-third {
                width: 100%;
                @media #{$tablet} {
                    width: 32.3333333%;
                }
            }

            .input-full {
                width: 100%;
                @media #{$tablet} {
                    width: 100%;
                }
            }

            .input-half {
                width: 100%;
                @media #{$tablet} {
                    width: 49.25%;
                }
            }
        }

        .error-message {
            display: block;
            font-weight: 700;
            margin-bottom: 1rem;
            font-size: .875rem;
            color: red;
        }
    }

    .yes-no-radio-group {

        > label {
            width: 100%;
        }
    }

    input[type="radio"],
    input[type="checkbox"] {
        position: relative;
        top: -3px;
    }

    .yes-no-radio {
        align-items: center;
        display: inline-flex;
        padding-right: 1rem;

        label {
            margin-bottom: 0;
            margin-left: 10px;
        }
    }
}

.loading-indicator {
    position: fixed;
    top: 0;
    width: calc(100% - 2px);
    margin: 0 auto;

    @media #{$tablet} {
        top: -3px;
        position: relative;
    }
}

.loading-text {
    margin: auto;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;

    p {
        margin-top: .5rem;
    }

    @media #{$desktop} {
        margin: auto;
    }
}


.gi-ul li::before {
    content: url('/img/icon-bolt.svg');
    left: -1.7rem;
    position: absolute
}
